<template>
  <div class="activity">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="activity-cont">
      <div class="top">
        <Button type="primary" @click="add">+ 添加主办方</Button>
      </div>
      <div class="table">
        <Table border :columns="columns" :data="dataList" :loading="loading"></Table>
      </div>
    </div>
    <!-- <Modal
        v-model="addModal"
        :title="type == 'add' ? '添加主办方' : '编辑主办方'"
    >
      <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="0">
        <el-form-item label="" prop="name">
          <p>主办logo上传：尺寸100px  * 100px</p>
          <el-input v-model="formValidate.name" size="large" placeholder="请输入主办方名称" class="w300"></el-input>
        </el-form-item>
        <el-form-item label="" prop="uploadfileId">
          <p>主办名称</p>
          <el-upload :action="constant.URL + '/uploadfile/upload'"
                     :data="{
                      type:3
                    }"
                     accept=".jpg,.png,.jpeg,.gif"
                     :headers="{
                      'access-token':Cookies.get('liteAccessToken')
                    }"
                     :on-success="uploadFileSuccess"
                     :show-file-list="false"
          >
            <div class="upload-btn" v-if="!uploadImg">
              <Icon type="ios-add" :size="50" />
            </div>
            <img :src="uploadImg" width="120" height="120" v-else />
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <Button @click="closeModal">取消</Button>
        <Button type="primary" @click="confirm">确定</Button>
      </div>
    </Modal> -->
    <DeleModal :status="deleModalStatus" txt="确定删除吗" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
    <el-drawer :visible.sync="addModal" :size="800" >
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ type==="add"?"添加主办方":"编辑主办方" }}</div>
        <div class="drawer-title-btns">
          <Button @click="closeModal" style="margin-right: 10px;">取消</Button>
          <Button type="primary" @click="confirm">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="0">
          <el-form-item label="" prop="name">
            <p>主办logo上传：尺寸100px  * 100px</p>
            <el-input v-model="formValidate.name" size="large" placeholder="请输入主办方名称" class="w300"></el-input>
          </el-form-item>
          <el-form-item label="" prop="uploadfileId">
            <p>主办名称</p>
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                      :data="{
                        type:3
                      }"
                      accept=".jpg,.png,.jpeg,.gif"
                      :headers="{
                        'access-token':Cookies.get(constant.tokenName)
                      }"
                      :on-success="uploadFileSuccess"
                      :show-file-list="false"
            >
              <div class="upload-btn" v-if="!uploadImg">
                <Icon type="ios-add" :size="50" />
              </div>
              <img :src="uploadImg" width="120" height="120" v-else />
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import util from '@/utils/tools.js'
import Cookies from "js-cookie";
import DeleModal from '../../components/deleteModal.vue';
export default {
  name: "index",
  data(){
    const validateUpload = (rule, value, callback)=>{
      this.$nextTick(()=>{
        if (!this.uploadImg) {
          return callback(new Error('请上传联系二维码'));
        }else{
          callback();
        }
      })
    }
    return {
      Cookies:Cookies,
      columns:[
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: 'logo',
          key: 'id',
          render: (h, params) => {
            return h('img', {
              attrs: {
                src:params.row.logo,
                style: 'width: 100px;border-radius: 2px;margin-top:4px;' //图片在表格中的样式
              },

            });
          }
        },
        {
          title: '主办方名称',
          key: 'name'
        },
        {
          title: '添加时间',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small',
                },
                style: {
                  marginRight: '5px',
                  color:'#4E83FF',
                },
                on: {
                  click: () => {
                    this.edit(params.row);
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small',
                },
                style: {
                  marginRight: '5px',
                  color:'#4E83FF',
                },
                on: {
                  click: () => {
                    this.delete(params.row);
                  }
                }
              }, '删除'),
            ]);
          }
        },
      ],
      dataList:[],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      status:'-1',
      statusList:[],
      statusObj:{},
      addModal:false,
      formValidate: {
        name: '',
        uploadfileId: '',
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入主办方名称', trigger: 'blur' }
        ],
        uploadfileId: [
          { required: true, message: '请上传联系二维码',validator: validateUpload, }
        ]
      },
      uploadImg:'',
      deleModalStatus:false,
      modalLoading:false,
      type:'',
      curData:{},
    }
  },
  components:{
    DeleModal
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){  //获取列表数据
      this.api.activity.organizeList().then((res)=>{
        this.dataList = res.list;
      })
    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      this.formValidate.uploadfileId = response.data.info.id;
      this.uploadImg = response.data.info.upload_path;
    },
    closeModal(){
      this.addModal = false;
    },
    confirm(){
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          let params = {
            uploadfileid:this.formValidate.uploadfileId,
            name:this.formValidate.name
          };
          if(this.type == 'edit'){
            params.id = this.curData.id;
            this.api.activity.organizeUpdate(params).then((res)=>{
              this.$Message.success('操作成功!');
              this.addModal = false;
              this.getList();
            })
          }else{
            this.api.activity.organizeCreate(params).then((res)=>{
              this.$Message.success('操作成功!');
              this.addModal = false;
              this.getList();
            })
          }

        }
      })
    },
    add(){
      this.type = 'add';
      this.addModal = true;
      this.formValidate.uploadfileId = '';
      this.formValidate.name = '';
      this.uploadImg = '';
    },
    edit(data){
      this.curData = data;
      this.type = 'edit';
      this.addModal = true;

      this.formValidate.uploadfileId = '';
      this.formValidate.name = this.curData.name;
      this.uploadImg = this.curData.logo;
    },
    delete(data){
      this.curData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curData.id
      }
      this.modalLoading = true;
      this.api.activity.organizeDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.activity{
  margin: 20px;
  font-size: 14px;
  .activity-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    .top{
      margin-bottom: 20px;
      >button{
        margin-right: 20px;
      }
    }
    .table{
      margin-top: 20px;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
}
.upload-btn{
  width: 120px;
  height: 120px;
  border: 1px solid #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
